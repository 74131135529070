@font-face {
  font-family: 'Industry';
  src: url('./fonts/Industry/Industry-Ultra.otf');
}

@font-face {
  font-family: 'FavoritMonoStd-Regular';
  src: url('./fonts/FavoritMonoStd/FavoritMonoStd-Regular.woff2') format('woff'),
    url('./fonts/FavoritMonoStd/FavoritMonoStd-Regular.woff') format('woff'),
    url('./fonts/FavoritMonoStd/FavoritMonoStd-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'FavoritStd-Light';
  src: url('./fonts/FavoritStd/FavoritStd-Light.woff2') format('woff'),
    url('./fonts/FavoritStd/FavoritStd-Light.woff') format('woff'),
    url('./fonts/FavoritStd/FavoritStd-Light.otf') format('opentype');
}

@font-face {
  font-family: 'FavoritStd-Bold';
  src: url('./fonts/FavoritBold/FavoritStd-Bold.woff2') format('woff'),
    url('./fonts/FavoritBold/FavoritStd-Bold.woff') format('woff'),
    url('./fonts/FavoritBold/FavoritStd-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'FavoritStd-Book';
  src: url('./fonts/FavoritBook/FavoritStd-Book.woff2') format('woff'),
    url('./fonts/FavoritBook/FavoritStd-Book.woff') format('woff'),
    url('./fonts/FavoritBook/FavoritStd-Book.otf') format('opentype');
}
